<template>
    <div class='out_storage'>
        <a-modal
        cancelText="取消"
        okText="新增"
        :zIndex="99"
        v-model:visible="showMachineDialog"
        @ok="addMachine"
        title="新增机器">
        <m-dialog-content  
                :forms="forms"
                :readOnly="false"
                :formItems="addMachineInfo"></m-dialog-content>
        </a-modal>
        <a-modal 
            :title="title" 
            :confirm-loading="confirmLoading" 
            cancelText="关闭"
            :ok-text="confirmText"
            :zIndex="9"
            @ok="machineHandle"
            v-model:visible="showDialog">
                <m-dialog-content  
                :rules="rules"
                :forms="forms"
                :readOnly="true"
                @modifyStatus="modifyStatus($event, false)"
                @onShelves="modifyStatus($event, true)"
                :formItems="popForms"></m-dialog-content>
            </a-modal>
        <m-form :formItems="formItems" @search="loadData(1)" @machineHandle="timeoutChange"></m-form>
        <m-table 
            :loading="loading" 
            :columns="columns" 
            :data="tableList" 
            :buttons="buttons"
            :totals="total"
            :currentPage="currentPage"
            @pageChange="loadData($event)"
            @detail="keeperDetail($event)" ></m-table>
    </div>
</template>
<script>
import { reactive, toRefs, h } from 'vue'
import { isValid, deviceCabinList, cabinDeviceList, addCabinDevice, offDevice, onDevice } from '../../utils/api'
import { formatTime, geneIndex, getValueFromObjArr } from '@/utils/util'
import { message, Modal } from 'ant-design-vue'
export default {
    name: '',
    setup() {
         const state= reactive({
             showMachineDialog: false,
             title: '设备详情',
             confirmLoading: false,
             showDialog: false,
             confirmText: '新增机器',
             columns: [
                 {title: '序号', dataIndex: 'index', key: 'index'},
                 {title: '小屋编号', dataIndex: 'cabinNo', key: 'cabinNo'},
                 {title: '小屋ID', dataIndex: 'id', key: 'id'},
                 {title: '店铺名称', dataIndex: 'shopName', key: 'shopName'},
                 {title: '设备个数', dataIndex: 'deviceCount', key: 'deviceCount'},
                 {title: '创建时间', dataIndex: 'createTime', key: 'createTime'},
                 {title: '小屋状态', dataIndex: 'statusText', key: 'statusText'},
                 { title: '操作', dataIndex: 'manage', key: 'manage', slots: {customRender: 'actions'}},
             ],
             buttons: [
                 { text: '查看详情', eventName: 'detail'}
             ],
             formItems: [
                {type: 'input', label: '小屋编号', value: undefined},
                {type: 'button', label: '查询', eventName: 'search'},
             ],
             popForms: [
                ],
             deviceInfo: [
                {label: '设备号', prop: 'no'},
                {label: '设备状态', prop: 'workStatusText'},
                {label: '设备彩种', prop: 'ticketTypeText'},
                {label: '彩种名称', prop: 'ticketName'},
                {label: '余票张数', prop: 'ticketBalanceNumber'},
             ],
             addMachineInfo: [
                {label: '设备ID', value: '', type: 'input'},
                {label: '上架时间', value: '', type: 'timeSelector'},
                {label: '备注', value: '', type: 'input'}
             ],
             forms: {timeout: ''},
             rules: {timeout: [{ required: true, message: '请输入超时时间', trigger: 'blur'}]},
             tableList: [],
             currentPage: 1,
             pageSize: 10,
             total: 0,
             selectedIDs: [],
             loading: false,
             status: 0,
             detail: {}, // 小屋详情
             machineList: [], // 机器列表
        })

        const deviceStatus = [
            [ 0, '初始化'],
            [ 1, '已上架'],
            [ 2, '已下架'],
            [ 3, '已删除'],
        ]

        const workStatus = [
           ['offline', '机器未联机'],
           ['idle', '在线空闲'],
           ['busy', '在线忙'],
           ['empty', '票售罄'],
           ['error', '错误'],
        ]
        const lotteryType = [
           ['TC', '体彩'],
           ['FC', '福彩'],
        ]

        // 获取数据
        async function  loadData(pageNum = null) {
            try {
                state.loading = true
                state.currentPage = pageNum || state.currentPage
                const params = {
                    pageNum: state.currentPage,
                    pageSize: state.pageSize,
                    cabinNo: state.formItems[0].value,
                }
                const result = await deviceCabinList(params)
                state.loading = false
                if(isValid(result)) {
                    state.total = result.data.total
                    result.data?.records?.forEach((ele) => {
                        ele.createTime = formatTime(ele.createTime)
                        ele.statusText = getValueFromObjArr(deviceStatus, ele.status)
                    })
                    state.tableList = geneIndex(result.data.records, state.pageSize, state.currentPage)
                }
            } catch(e) {
                state.loading = false
                console.error(e)
            }
        }
        // 查看详情
        async function keeperDetail(event) {
            try {
                state.detail = event || state.detail
                console.log(state.detail)
                const result = await cabinDeviceList(state?.detail?.id)
                if (isValid(result)) {
                    state.machineList = result.data.records
                    state.popForms = []
                    result.data.records.forEach((ele, eleIndex) => {
                        state.deviceInfo.forEach((deviceInfo, deviceIndex) => {
                            if (deviceIndex == 0) {
                                state.popForms.push({
                                    label: '--------------',
                                    value: `设备${eleIndex+1} :`+'--------------',
                                    type: 'input'
                                })
                            }
                            ele.workStatusText = getValueFromObjArr(deviceStatus, ele.deviceCabinInfoStatus)
                            ele.ticketTypeText = getValueFromObjArr(lotteryType, ele.ticketType)
                            const formInfo = {
                                label: deviceInfo.label,
                                value: ele[deviceInfo.prop] || '暂无',
                                type: 'input'
                            }
                            if(ele.deviceCabinInfoStatus == '1' && deviceInfo.label == '设备状态') {
                                formInfo.eventName = 'modifyStatus'
                                formInfo.eventLabel = '下架'
                            } else if (ele.deviceCabinInfoStatus == '2' && deviceInfo.label == '设备状态') {
                                formInfo.eventName = 'onShelves'
                                formInfo.eventLabel = '上架'
                            }
                            state.popForms.push(formInfo)
                        })
                    })
                    state.showDialog = true
                }
            } catch(e) {
                console.error(e)
            }
        }
        loadData()
        function machineHandle() {
            // state.showDialog = false
            state.showMachineDialog = true
        }
        // 新增机器
        async function addMachine() {
            try {
                const item = state.addMachineInfo
                if (!item[0].value) {
                    message.warn(`${item[0].label}不能为空`)
                    return
                }
                if (!item[1].value) {
                    message.warn(`${item[1].label}不能为空`)
                    return
                }
                const params = {
                    cabinId: state.detail.id,
                    deviceNo: item[0].value,
                    startTime: formatTime(item[1].value, 'YYYY-MM-DD HH:mm:ss') ,
                    remark: item[2].value
                }
                const result = await addCabinDevice(params)
                if (result.code == 0) {
                    message.success('新增机器成功')
                    await keeperDetail()
                }
            } catch(e) {
                console.error(e)
            }
        }
        async function offMachine(info) {
            try {
                const result = await offDevice(info.deviceCabinInfoId)
                if (result.code == 0) {
                    info.status = 2
                    message.success('下架成功')
                    await keeperDetail()
                }
            } catch(e) {
                console.error(e)
            }
        }
        async function onShelves(info) {
            try {
                const result = await onDevice(info.deviceCabinInfoId)
                if (result.code == 0) {
                    info.status = 1
                    message.success('上架成功')
                    await keeperDetail()
                }
            } catch(e) {
                console.error(e)
            }
        }
        function modifyStatus(info, isIn) {
            const index = parseInt(info/6)
            Modal.confirm({
                title: `${isIn ? '上架' : '下架'}`,
                okText: '确认',
                cancelText: '取消',
                content: () => `请问是否确认${isIn ? '上' : '下'}架？`,
                onOk() {
                    if (isIn) {
                        onShelves(state.machineList[index])
                    } else {
                        offMachine(state.machineList[index])
                    }
                },
                onCancel() {
                    Modal.destroyAll()
                }
            })
            console.log(index)
            console.log(info)
        }
        function timeoutChange(){
            //
        }
        return {
            ...toRefs(state),
            keeperDetail,
            loadData,
            machineHandle,
            addMachine,
            modifyStatus,
            timeoutChange
        }
    },
}
</script>
<style lang='scss' scoped>
</style>